export const dateFormat = 'D.MM.YYYY';
export const dateTimeFormat = 'D.MM.YYYY HH:mm';
export const timeFormat = 'HH:mm';

export namespace WeekDays {
  export enum WeekDaysId {
    MONDAY = 'mon',
    TUESDAY = 'tue',
    WEDNESDAY = 'wed',
    THURSDAY = 'thu',
    FRIDAY = 'fri',
    SATURDAY = 'sat',
    SUNDAY = 'sun',
  }

  export interface IWeekDay {
    id: WeekDaysId;
    label: string;
  }

  export const WeekDaysArray: IWeekDay[] = [
    {
      id: WeekDaysId.MONDAY,
      label: 'WEEK_DAYS.MONDAY'
    },
    {
      id: WeekDaysId.TUESDAY,
      label: 'WEEK_DAYS.TUESDAY'
    },
    {
      id: WeekDaysId.WEDNESDAY,
      label: 'WEEK_DAYS.WEDNESDAY'
    },
    {
      id: WeekDaysId.THURSDAY,
      label: 'WEEK_DAYS.THURSDAY'
    },
    {
      id: WeekDaysId.FRIDAY,
      label: 'WEEK_DAYS.FRIDAY'
    },
    {
      id: WeekDaysId.SATURDAY,
      label: 'WEEK_DAYS.SATURDAY'
    },
    {
      id: WeekDaysId.SUNDAY,
      label: 'WEEK_DAYS.SUNDAY'
    },
  ];
}
