import {Component, Input, OnInit} from '@angular/core';
import {ExpositionInterface} from "../../interfaces/exposition.interface";
import {Model3dDialogComponent} from "../model-3d-dialog/model-3d-dialog.component";
import {DialogService} from "../../../core/services/dialog.service";

@Component({
  selector: 'app-model-3d-hotspot',
  templateUrl: './model-3d-hotspot.component.html',
  styleUrls: ['./model-3d-hotspot.component.less']
})
export class Model3dHotspotComponent implements OnInit {
  @Input() data: any;

  modelTypes = ExpositionInterface.model3dTypes;
  type: ExpositionInterface.model3dTypes = ExpositionInterface.model3dTypes.popup;

  constructor(private dialogService: DialogService) { }

  ngOnInit() {
    this.type = this.data.modelType;
  }

  openDialog() {
    this.data.viewport = {
      width: 620,
      height: 620,
    }
    this.dialogService.openDialog(Model3dDialogComponent, {
      data: this.data,
    })
  }

}

