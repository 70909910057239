import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {PageConfigStore} from "../../stores/page-config.store";
import {AppConfig} from "../../core/config";

@Component({
  selector: 'app-main-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.less']
})

export class NewsPageComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];

  constructor(
    private pageConfigStore: PageConfigStore,
  ) {
  }

  content: any;

  ngOnInit() {
    this.pageConfigStore.updateTitle('Новости');
    this.content = {
      iframeUrl: `${AppConfig.apiUrl}/ru/news?mode=minimal`,
    };
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
