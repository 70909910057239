import {Component, OnInit} from '@angular/core';
import {AppConfigStore} from '../../stores/app-config.store';
import {IAppConfig} from '../../interfaces/app-config.interface';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})

export class MenuComponent implements OnInit {
  menu: IAppConfig.IMenuLink[] = [];

  constructor(private appConfig: AppConfigStore) {}

  ngOnInit() {
    this.menu = this.appConfig.state.menu;
  }

}
