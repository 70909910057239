import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IGetUsers} from '../interfaces/user.interface';
import {AppConfigStore} from "../stores/app-config.store";

@Injectable()
export class BroadcastResource {
  constructor(private httpClient: HttpClient,
              private appConfig: AppConfigStore,) { }

  getEvent(id: string): Observable<any> {
    return this.httpClient.get(`${this.appConfig.state.apiUrl}/api/v2/events/${id}`) as Observable<IGetUsers>;
  }

  getClosestBroadcast(id: string): Observable<any> {
    return this.httpClient.get(`${this.appConfig.state.apiUrl}/api/v2/broadcasts/${id}?closest`) as Observable<any>;
  }
}
