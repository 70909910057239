import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {ChatStore} from '../../stores/chat.store';
import {map} from 'rxjs/operators';
import {IMessageItem} from '../../interfaces/chat.interface';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.less']
})
export class ChatComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  messagesList: any[] = [];
  offset = 0;
  offsetValue = 20
  infiniteScrollDisabled = false;

  @ViewChild('scrolledContainer') scrolledContainer: ElementRef;

  constructor(private chatStore: ChatStore) {}

  ngOnInit(): void {
    this.subs.push(
      this.chatStore.getChannelId(4147)
        .pipe(map((response: any) => response.data.id))
        .subscribe(channelId => this.chatStore.setChatChannels(channelId)),

      this.chatStore.state$.subscribe(state => {
        if (state.activeChannelId) {
          this.chatStore.getMessageList(state.activeChannelId, this.offset)
            .pipe(map((response: any) => response.data))
            .subscribe(messageData => {
              this.messagesList = messageData

              setTimeout(() => {
                this.scrolledContainer.nativeElement.scrollTop = this.scrolledContainer.nativeElement.scrollHeight;
              }, 0)
            });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  onScroll() {
    this.chatStore.getMessageList(this.chatStore.state.activeChannelId, this.offset)
      .pipe(map( (res: any) => {
        return res.data
      }))
      .subscribe((messages: IMessageItem[]) => {
        if (messages.length) {
          this.messagesList.push(...messages);
          this.offset += this.offsetValue;
        } else {
          this.infiniteScrollDisabled = true;
        }
      })
  }

  onMessageSend() {
    this.scrolledContainer.nativeElement.scrollTop = this.scrolledContainer.nativeElement.scrollHeight;
  }
}
