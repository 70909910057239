import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-internal-content-template',
  templateUrl: './internal-content-template.component.html',
  styleUrls: ['./internal-content-template.component.less']
})
export class InternalContentTemplateComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  @Input() content: any;

  constructor() {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
