import {Component, Input,  AfterViewInit, ViewChild} from '@angular/core';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {DialogService} from "../../../core/services/dialog.service";
import {Model3dDialogComponent} from "../model-3d-dialog/model-3d-dialog.component";
import {ExpositionInterface} from "../../interfaces/exposition.interface";

@Component({
  selector: 'app-model-3d-viewport',
  templateUrl: './model-3d-viewport.component.html',
  styleUrls: ['./model-3d-viewport.component.less']
})
export class Model3dViewportComponent implements AfterViewInit {


  @Input() data: any;
  @ViewChild('modelContainer', {static: true}) modelContainer: any;

  modelTypes = ExpositionInterface.model3dTypes;

  THREE = window['THREE'];
  FBXLoader = window['THREE']['FBXLoader'];

  constructor() { }

  ngAfterViewInit() {
    const THREE = this.THREE;
    const FBXLoader = this.FBXLoader;

    const clock = new THREE.Clock(); // часики - для измерения промежутков времени, что бы обновлять анимацию
    let mixer = null; // проигрыватель анимаций модели

    let scene = new THREE.Scene();

    let camera = new THREE.PerspectiveCamera( 75, this.data.viewport.width / this.data.viewport.height, 0.1, 1000 );

    let  renderer = new THREE.WebGLRenderer( { alpha: true } );
    renderer.setClearColor( 0x000000, (this.data.modelType === this.modelTypes.model) ? 0 : 1 );
    renderer.setSize( this.data.viewport.width, this.data.viewport.height );

    let controls = new OrbitControls( camera, renderer.domElement );
    controls.update();

    this.modelContainer.nativeElement.appendChild( renderer.domElement );

    // добавляем источники света
    // первый
    let light = new THREE.HemisphereLight( 0xffffff, 0xffffff );
    light.position.set( 0, 2000, 0 ); // ставим куда надо
    scene.add( light ); // добавляем на сцену

    // второй
    light = new THREE.HemisphereLight( 0x888888, 0x888888 );
    scene.add( light );

    // третий
    light = (new THREE.DirectionalLight( 0xffffff )) as any;
    light.position.set( 0, 200, 100 );
    light.castShadow = true; // кастует тени (предыдущие два не приводят к появлению теней - чисто для яркости)
    (light.shadow.camera as any).top = 180;
    (light.shadow.camera as any).bottom = - 100;
    (light.shadow.camera as any).left = - 120;
    (light.shadow.camera as any).right = 120;
    scene.add( light );

    (new FBXLoader()).load( this.data.src, ( object ) => { setTimeout(() => { // фишка - внутри callback'а загрузчика ошибки не выводятся в консоль - трудно отлаживать, поэтому код переоборачиваем в setTimeout, что бы как бы выйти из контекста callback'а. Если не понятно - поставьте throw Error('My error') первой строкой и попробкйте посмотреть консоль с и без setTimeout.

      mixer = new THREE.AnimationMixer( object ); // создаем проигрыватель анимаций
      let action = mixer.clipAction( (object as any).animations[ 0 ] ); // находим нужную анимацию и вставляем в проигрыватель
      action.play(); // играем (на самом деле анимация не играет сама по себе, как <video autoplay/> - об этом чуть ниже)

      object.traverse( function ( child ) { // проходимся по всех кускам модели
        if ( (child as any).isMesh ) {
          child.castShadow = true; // говорим, что кусок может отбрасывать тень
          child.receiveShadow = true; // говорим, что кусок может принимать тень
        }
      } );

      let box: any = new THREE.Box3().setFromObject( object ); // узнаем параллелепипед в который умещается модель, т.е узнаем её итоговую ширину, высоту и толщину
      box.center(object.position); // смещаем центр в центр модели (потому что у нас модель стоит как бы в верхей половине мира, а надо сторго по центру)
      object.position.multiplyScalar(-1); // умножаем координаты на -1, что бы вниз спустилась - в центр по вертикали
      let model = new THREE.Group(); // переобрачиваем модель в группу (это как div в div.wrapper), что бы потом вращалась нормально, а не вокруг какой попало оси
      model.add( object ); // добавляем модель в группу
      scene.add(model);

      let gridHelper = new THREE.GridHelper( 120, 3, 0xffffff, 0xffffff );
      gridHelper.geometry.translate(0,-box.getSize().y/2,0);
      (gridHelper.material as any).opacity = 0.4;
      (gridHelper.material as any).transparent = true;

      const grid = new THREE.Group();
      grid.add(gridHelper);
      scene.add( grid );

    },100); } );

    camera.position.z = 200;

    const animate = () => {
      requestAnimationFrame( animate ); // сразу резервируем следующий вызов, когда будет окно браузерного движка для рисования

      let delta = clock.getDelta(); // вычисляем сколько времени прошло с последнего вычисления
      if ( mixer ) mixer.update( delta ); // обновляем проигрыватель анимации - смешаем время, через это меняется состояние анимации

      controls.update();

      renderer.setClearColor( 0x000000, (this.data.modelType === this.modelTypes.model) ? 0 : 1 ); // чистим канву - напрочь, перый агрумент - цвет, второй - прозрачность
      renderer.render( scene, camera ); // рисуем! (* на нашей канве: как наша камера видит нашу сцену )
    }

    animate(); // запускаем отрисовку
  }
}

