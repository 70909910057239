import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {IAppConfig} from '../../interfaces/app-config.interface';
import {AppConfigStore} from '../../stores/app-config.store';
import {IRoute} from '../../interfaces/router.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})

export class HeaderComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];
  routes = IRoute.Routes;
  languages: IAppConfig.LANGUAGES[] = [];
  activeLanguage: IAppConfig.LANGUAGES;
  theme: IAppConfig.ITheme = null;
  logo = '';

  constructor(private appConfig: AppConfigStore) {}

  ngOnInit(): void {
    this.activeLanguage = this.appConfig.state.activeLanguage;

    this.subs.push(this.appConfig.state$.subscribe(state => {
      this.languages = state.languages;
      this.theme = state.themes[0];
      this.activeLanguage = state.activeLanguage;
      this.logo = this.appConfig.state.themes[0].logo;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }

}
