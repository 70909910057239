import {MatDialog} from "@angular/material/dialog";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog) { }

  openDialog(dialogComponent, data) {
    this.dialog.open(dialogComponent, data);
  }
}
