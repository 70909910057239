import {NgModule} from "@angular/core";
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  imports: [
    MatDialogModule
  ],
  providers: [
  ],
  declarations: [

  ],
  exports: [
    MatDialogModule
  ]
})
export class MatMaterialModule {}
