import {Component, OnDestroy, OnInit} from '@angular/core';
import {BroadcastResource} from "../../resources/broadcast.resource";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {PageConfigStore} from "../../stores/page-config.store";

@Component({
  selector: 'app-expo-page',
  templateUrl: './expo-page.component.html',
  styleUrls: ['./expo-page.component.less']
})

export class ExpoPageComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];
  content: any;

  constructor(
    private pageConfigStore: PageConfigStore,
  ) {
  }

  ngOnInit() {
    this.pageConfigStore.updateTitle('Выставка');
    this.content = {
      expo: {},
    };
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
