import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {dateTimeFormat} from '../interfaces/forms.interface';

@Pipe({
  name: 'czPrintableDate'
})
export class PrintableDatePipe implements PipeTransform {
  transform(input: string|number|any): string {
    return input ? moment(input * 1000).format(dateTimeFormat) : '';
  }
}
