import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import {AppConfig} from '../config';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiKey = AppConfig.apiKey ? `Api-key ${AppConfig.apiKey}` : '';
    const token = this.cookieService.get('token') ? `Bearer ${this.cookieService.get('token')}` : '';

    const headers: any = {};
    if (apiKey) {
      headers.Authorization = apiKey;
    }
    if (token) {
      headers['X-Api-Authorization'] = token;
    }

    // Standard set of our custom headers.
    request = request.clone({
      setHeaders: {...headers},
      withCredentials: true,
    });

    // Set default headers.
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json'
        }
      });
    }

    return next.handle(request);
  }
}
