import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AppConfigStore} from '../../stores/app-config.store';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../core/config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})

export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  subs: Subscription[] = [];
  messages: { id: number; text: string; }[] = [];
  text = '';
  interval = null;
  queryInterval = null;
  readonly timeDuration = 15000;

  constructor(
    private appConfig: AppConfigStore,
    private httpClient: HttpClient,
  ) {}

  ngOnInit(): void {
    this.getMessages();
    this.queryInterval = setInterval(() => this.getMessages(), 1000 * 60 * 10);
  }

  getMessages() {
    if (this.subs.length) this.subs.forEach(subscription => subscription.unsubscribe());

    this.subs.push(
      this.httpClient.get(`${AppConfig.apiUrl}/api/v1/tickers/get`).subscribe((response: any) => {
        this.messages = response.data;
        this.text = this.messages[0].text;
      })
    );
  }

  ngAfterViewInit(): void {
    let index =  this.messages.length === 1 ? 0 : 1;
    this.interval = setInterval(() => {
      if (this.messages[index]) {
        this.text = this.messages[index].text;
      }
      index++;
      if (index === this.messages.length) index = 0;
    }, this.timeDuration);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    clearInterval(this.queryInterval);
    this.interval = null;
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
