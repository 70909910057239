import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CountryISO, SearchCountryField, TooltipLabel} from 'ngx-intl-tel-input';

@Component({
  selector: 'app-feedback-hotspot',
  templateUrl: './feedback-hotspot.component.html',
  styleUrls: ['./feedback-hotspot.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class FeedbackHotspotComponent implements OnInit  {

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Russia];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
    name: new FormControl(undefined, [Validators.required]),
    surname: new FormControl(undefined, [Validators.required]),
    email: new FormControl(undefined, [Validators.required]),
    text: new FormControl(undefined, [Validators.required]),
  });

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  @Input() data: any;


  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    // this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.src);
  }

}
