import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CountryISO, SearchCountryField, TooltipLabel} from 'ngx-intl-tel-input';

@Component({
  selector: 'app-contact-hotspot',
  templateUrl: './contact-hotspot.component.html',
  styleUrls: ['./contact-hotspot.component.less'],
})
export class ContactHotspotComponent implements OnInit  {

  @Input() data: any;


  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    // this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.src);
  }

}
