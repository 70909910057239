import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgScrollbar} from "ngx-scrollbar";

@Component({
  selector: 'app-broadcast-overlay',
  templateUrl: './broadcast-overlay.component.html',
  styleUrls: ['./broadcast-overlay.component.less']
})
export class BroadcastOverlayComponent implements OnInit, OnChanges, OnDestroy {
  subs: Subscription[] = [];

  isOverlayShowed: boolean = false;
  isInfoOpened: boolean = false;
  isNextOpened: boolean = false;

  @Input() info: string;
  @Input() schedule: any[];

  @ViewChild('scheduleScroll', { static: true }) scheduleScroll: NgScrollbar;


  reports = [];

  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges(changes) {
    if (changes['info']) {
      this.isInfoOpened = false;
    }
    if (changes['schedule']) {
      this.isInfoOpened = false;
      this.updateScroll();
      this.reports = this.schedule?.filter( item => item.current && item?.relatedUsers?.length && item?.relatedUsers.filter( list => list?.users?.length).length);
    }
    if (changes['next']) {
      this.isNextOpened = false;
    }
  }

  closeAll() {
    this.isInfoOpened = false;
    this.isNextOpened = false;
  }

  toggleNext() {
    this.isInfoOpened = false;
    this.isNextOpened = !this.isNextOpened;
    if (this.isNextOpened) {
      this.updateScroll();
    }
  }

  toggleInfo() {
    this.isNextOpened = false;
    this.isInfoOpened = !this.isInfoOpened;
  }

  updateScroll() {
    setTimeout( () => {
      try {
        this.scheduleScroll.scrollToElement('.broadcast-overlay__next-item--next', { duration: 0 });
      }
      catch (e) { }
    })
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
