import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConfigStore} from '../../stores/app-config.store';
import {IAppConfig} from '../../interfaces/app-config.interface';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-main-screen-banner',
  templateUrl: './main-screen-banner.component.html',
  styleUrls: ['./main-screen-banner.component.less']
})
export class MainScreenBannerComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  banner: IAppConfig.IBanner = null;

  constructor(private appConfig: AppConfigStore) {}

  ngOnInit(): void {
    this.subs.push(this.appConfig.state$.subscribe(state => {
      this.banner = state.themes[0].banner;
    }));
  }

  openLink() {
    window.location.href = this.banner.banner_link;
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
