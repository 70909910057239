import {IAppConfig} from '../interfaces/app-config.interface';
import {IStore, StoreService} from '../core/store/store';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AppConfig, SetAppConfig} from '../core/config';

@Injectable()
export class AppConfigStore extends StoreService<IAppConfig.IConfig> implements IStore<IAppConfig.IConfig> {

  static defaultState: IAppConfig.IConfig = {
    apiUrl: '',
    apikey: '',
    blocks_display: [],
    languages: [],
    menu: [],
    themes: [],
    uid: null,
    default_language: IAppConfig.LANGUAGES.RU,
    main_chat_channel: null,

    isLoggedIn: false,
    activeLanguage: IAppConfig.LANGUAGES.RU,
  };

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private activeRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    super(AppConfigStore.defaultState);
  }

  initApp() {
    // Get configs from server.
    window['SpaAppConfig'] = {};

    this.httpClient.get('./config/config.json')
      .subscribe((response: { status: string; data: any; }) => {
        this.stateSubject.next({
          ...this.state,
          apiUrl: response.data.apiUrl,
        });
        this.getAppConfig(response.data.apiUrl);
      }, error => {
        this.stateSubject.next({
          ...this.state,
          apiUrl: 'https://rosmedobr2020.gate1.campuz.org',
        });
        this.getAppConfig(this.state.apiUrl);
      });
  }

  getAppConfig(apiUrl: string) {
    this.httpClient.get(`${apiUrl}/api/v1/application/init`)
      .subscribe((response: any) => {
        this.stateSubject.next({
          ...this.state,
          ...response.data,
        });

        this.checkAuth();
        this.checkLanguage();

        SetAppConfig({
          apiKey: this.state.apikey,
          apiUrl: this.state.apiUrl,
          langCode: this.state.activeLanguage,
        });
        this.translateService.setDefaultLang(IAppConfig.LANGUAGES.RU);
        this.translateService.use(AppConfig.langCode ? AppConfig.langCode : 'ru');
      });
  }

  checkAuth() {
    if (!!this.cookieService.get('token')) {
      this.stateSubject.next({
        ...this.state,
        isLoggedIn: true,
      });
    }
  }

  checkLanguage() {
    const lang = this.activeRoute.snapshot.firstChild?.paramMap.get('lang');
    if (lang) {
      this.stateSubject.next({
        ...this.state,
        activeLanguage: lang as IAppConfig.LANGUAGES,
      });
    }
  }
}

