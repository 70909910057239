export namespace ExpositionInterface {

  export enum hotspotTypes {
    NAVIGATION_ARROW = 'navigation-arrow',
    IFRAME = 'iframe',
    AUDIO = 'audio',
    TEXT = 'text',
    MAP = 'map',
    MODEL_3D = 'model-3d',
    IMAGE = 'image',
    IMAGES_GALLERY = 'images-gallery',
    FEEDBACK = 'feedback',
    CONTACT = 'contact',
  }

  export enum galleryTypes {
    ONLY_IMAGE = 'only-image',
    WITH_THUMBS = 'with-thumbs',
    WITH_TEXT = 'with-text',
    CENTRED = 'centred',
  }

  export enum model3dTypes {
    model = 'model',
    popup = 'popup',
  }
}
