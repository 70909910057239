import { Injectable } from '@angular/core';
import {IStore, StoreService} from '../../core/store/store';
import {IChatChannelItem, IChatStore, ISendMessageData} from '../interfaces/chat.interface';
import {HttpClient} from '@angular/common/http';
import {AppConfigStore} from '../../stores/app-config.store';

@Injectable({
  providedIn: 'root'
})
export class ChatStore extends StoreService<IChatStore> implements IStore<IChatStore> {

  static defaultState: IChatStore = {
    activeChannelId: null,
    baseUrl: 'https://rosmedobr2020.gate1.campuz.org//api/v2/',
    chatChannels: [],
  };

  constructor(private _httpClient: HttpClient, private appConfigStore: AppConfigStore) {
    super(ChatStore.defaultState);
  }

  changeChannel(channel: number) {
    this.stateSubject.next({
      ...this.state,
      activeChannelId: channel,
    });
  }

  setChatChannels(broadCastChannelId: number) {
    const chatChannels: IChatChannelItem[] = [
       {
        channelName: 'Чат трансляции',
        channelId: broadCastChannelId,
      }
    ];

    if (this.appConfigStore.state.main_chat_channel) {
      chatChannels.push({
        channelName: 'Общий чат',
        channelId: this.appConfigStore.state.main_chat_channel,
      })
    }

    this.stateSubject.next({
      ...this.state,
      chatChannels
    });

    this.changeChannel(chatChannels[0].channelId);
  }


  getChannelId(nodeId: number) {
     return this._httpClient.get(`${this.state.baseUrl}channel/broadcasts?type=broadcast_item&identifier=${nodeId}`);
  }

  getMessageList(channelId: number, offset) {
    return this._httpClient.get(`${this.state.baseUrl}channel/${channelId}/post/list/?limit=20&offset=${offset}&sort=changed`);
  }

  sendMessage(requestData: ISendMessageData) {
    return this._httpClient.post(`${this.state.baseUrl}channel/${this.state.activeChannelId}/post`, {...requestData})
  }

  likeMessage(method, id) {
    this._httpClient[method](`${this.state.baseUrl}post/${id}/like`).subscribe();
  }
}
