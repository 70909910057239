import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-audio-hotspot',
  templateUrl: './audio-hotspot.component.html',
  styleUrls: ['./audio-hotspot.component.less']
})
export class AudioHotspotComponent implements OnInit  {

  @Input() data: any;

  src: SafeUrl = '';

  wavesurfer: any;
  isPlaying: boolean = false;

  WaveSurfer = window['WaveSurfer'];

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.src);

    this.wavesurfer = this.WaveSurfer.create({
      container: '#waveform',
      waveColor: 'white',
      progressColor: 'red'
    });

    this.wavesurfer.load(this.data.src);
  }

  play() {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.wavesurfer.play();
    }
    else {
      this.isPlaying = false;
      this.wavesurfer.pause();
    }
  }
}
