export interface IChatStore {
  activeChannelId: number;
  baseUrl: string;
  chatChannels: IChatChannelItem[]
}

export interface IChatChannelItem {
  channelName: string,
  channelId: number,
}

export interface IMessageItem {
  id: number;
  name: string;
  info: string;
  format: string;
  created: number;
  changed?: number;
  viewed: boolean;
  liked: boolean;
  likes: number;
  accessType: AccessType;
  channel: number;
  author: number;
  authorName?: string;
  authorSurName?: string;
  authorImageUrl?: string;
  imageUrl?: string;
}

export interface ISendMessageData {
  name: string;
  info: string;
  accessType: AccessType;
  file?: IFileToSend;
}

export interface IFileToSend {
  data: string;
  type: string;
  name: string;
}

export interface IUploadPictures {
  id: string;
  url: string;
  originalFiles: File;
}

export enum AccessType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}
