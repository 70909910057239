import {NgModule} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApiInterceptor} from './services/api-interceptor.service';
import {AppConfig} from './config';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
  ],
  declarations: [
  ],
  entryComponents: [
  ],
  exports: [
  ]
})

export class CoreModule {
  constructor() {
  }
}
