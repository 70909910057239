import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {ChatStore} from '../../stores/chat.store';
import {AccessType, IUploadPictures} from '../../interfaces/chat.interface';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.less']
})
export class ChatInputComponent implements OnInit {

  constructor(private chatStore: ChatStore,
              private domSanitizer: DomSanitizer) { }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('fileUpload') fileUpload: ElementRef;
  @Output() onMessageSend = new EventEmitter<any>();

  pictures: IUploadPictures[]= [];
  messageValue: string;
  showOptionsMenu: boolean = false;

  picturesUploadErrors: string[] = []

  ngOnInit(): void {

  }

  async areaEnterClick(e) {
    if (e && e.keyCode === 13) {
      if (e.shiftKey) {
        this.autosize.resizeToFitContent(true)
      } else {
        await this.sendMessage();
      }
    }
  }

  async createRequestDataForMessage() {
    const requestData: any = {
      name: this.messageValue,
      info: this.messageValue,
      accessType: AccessType.PUBLIC,
    }

    if (this.pictures.length) {
      requestData.file = {
        type: this.pictures[0].originalFiles.type.replace('image/', ''),
        name: this.pictures[0].id,
        data: await this.fileToBase64(this.pictures[0].originalFiles).then((res: string) => res.split("base64,")[1]),
      }
    }
    return requestData
  }

  fileToBase64(file) {
     return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e)
    });
  }

  sanitize(url: string){
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  async sendMessage() {
    if ((this.messageValue && this.messageValue.trim()) || this.pictures.length) {
      this.chatStore.sendMessage(await this.createRequestDataForMessage())
        .subscribe(() => {
          this.messageValue = '';
          this.pictures = [];
          this.autosize.reset();
          this.onMessageSend.emit();
        })
    }
  }

  triggerMenu() {
    this.showOptionsMenu = !this.showOptionsMenu;
  }

  addPhoto() {
    this.fileUpload.nativeElement.click();
  }

  onFileUpload(files: FileList) {
    this.generatePhotoPreview(files);
  }

  generatePhotoPreview(files: FileList) {
    if (!files.length) return;

    this.checkErrors(files)

    if (!this.picturesUploadErrors.length) {
      this.createPicturesUrl(files)
        .then((pictures: IUploadPictures[]) => {
          this.pictures = pictures;
        })
    }
  }

  checkErrors(files: FileList) {
    this.picturesUploadErrors = [];

    if (files.length > 5) {
      this.picturesUploadErrors.push('*Нельзя добавить больше 5 файлов')
    }
  }

  deleteUploadPicture(id) {
    this.pictures = this.pictures.filter(picture => picture.id !== id);
  }

  createPicturesUrl(files: FileList): Promise<IUploadPictures[]> {
    let picturesArr = [];

    return new Promise((resolve, reject) => {
      Array.from(files, (file: File) => {
        picturesArr.push({
          url: URL.createObjectURL(file),
          id:  Math.random().toString(36).substr(2, 9),
          originalFiles: file,
        });
      })

      resolve(picturesArr);
    });
  }
}
