
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {IStore, StoreService} from '../core/store/store';
import { Injectable } from '@angular/core';
import {IPageConfig} from '../interfaces/page-config.interface';

@Injectable()
export class PageConfigStore extends StoreService<IPageConfig> implements IStore<IPageConfig> {

  static defaultState: IPageConfig = {
    pageTitle: '',
  };

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    super(PageConfigStore.defaultState);
  }

  getPageConfig() {

  }

  updateTitle(pageTitle: string) {
    this.stateSubject.next({
      ...this.state,
      pageTitle
    });
  }
}

