import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-text-hotspot',
  templateUrl: './text-hotspot.component.html',
  styleUrls: ['./text-hotspot.component.less']
})
export class TextHotspotComponent implements OnInit  {

  @Input() data: any;

  text: string = '';

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.text = this.data.text;
  }

}
