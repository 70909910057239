import {Component} from '@angular/core';

@Component({
  selector: 'app-map-hotspot',
  templateUrl: './map-hotspot.component.html',
  styleUrls: ['./map-hotspot.component.less']
})
export class MapHotspotComponent {

  constructor() { }

}
