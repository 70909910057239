import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ExpositionInterface} from "../../interfaces/exposition.interface";

@Component({
  selector: 'app-images-gallery-hotspot',
  templateUrl: './images-gallery-hotspot.component.html',
  styleUrls: ['./images-gallery-hotspot.component.less']
})
export class ImagesGalleryHotspotComponent implements OnInit, AfterViewInit  {

  @Input() data: any;

  @ViewChild('galleryTop', { static: false }) galleryTop: any;
  @ViewChild('galleryThumbs', { static: false }) galleryThumbs: any;

  Swiper: any = window['Swiper'];

  galleryTypes = ExpositionInterface.galleryTypes;
  type: ExpositionInterface.galleryTypes = ExpositionInterface.galleryTypes.ONLY_IMAGE;
  slides = [];


  constructor() { }

  ngOnInit() {
    this.slides = this.data.images;
    this.type = this.data.galleryType;
  }

  ngAfterViewInit() {
    let galleryThumbs;

    if (this.type === this.galleryTypes.WITH_THUMBS) {
      galleryThumbs = new this.Swiper(this.galleryThumbs.nativeElement, {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });
    }

    let galleryTop = new this.Swiper(this.galleryTop.nativeElement, {
      spaceBetween: (this.type === this.galleryTypes.CENTRED) ? 90 : 10,
      slidesPerView: (this.type === this.galleryTypes.CENTRED) ? 'auto' : 1,
      centeredSlides: (this.type === this.galleryTypes.CENTRED) ? true : null,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: (this.type === this.galleryTypes.WITH_THUMBS) ? {
        swiper: galleryThumbs
      } : null
    });

  }

}
