import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  emitEvent = new EventEmitter();
  subsVar: Subscription;

  constructor() { }

  updateCzTableList(config: any = { isResetPaginator: false, isNeedClearSelected: false }) {
    this.emitEvent.emit(config);
  }
}
