import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import Timer = NodeJS.Timer;
import {Router} from "@angular/router";

@Component({
  selector: 'app-broadcast-card',
  templateUrl: './broadcast-card.component.html',
  styleUrls: ['./broadcast-card.component.less']
})
export class BroadcastCardComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  duration = 0;
  interval: Timer;

  @Input() broadcast: any;

  constructor(private router: Router, ) {}

  ngOnInit(): void {
    this.duration = Date.now() - this.broadcast.dateStart;
    this.interval = setInterval( () => {
      this.duration++;
    }, 1000)
  }

  openBroadcast(event) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['broadcast', this.broadcast.id])
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
