import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConfigStore} from '../../stores/app-config.store';
import {Subscription} from 'rxjs';
import {PageConfigStore} from "../../stores/page-config.store";
import { Location } from '@angular/common';
import {IAppConfig} from '../../interfaces/app-config.interface';

@Component({
  selector: 'app-main-screen-header',
  templateUrl: './main-screen-header.component.html',
  styleUrls: ['./main-screen-header.component.less']
})
export class MainScreenHeaderComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  title = '';
  menu: IAppConfig.IMenuLink[] = [];

  get showButton(): boolean {
    let show = true;
    const pathname = window.location.pathname;
    this.menu.forEach(item => {
      if (pathname.includes(item.href)) {
        show = false;
      }
    });
    if (pathname.includes(IAppConfig.LANGUAGES.RU) || pathname.includes(IAppConfig.LANGUAGES.EN) || pathname.includes('/')) {
      show = false;
    }
    return show;
  }

  constructor(private appConfig: AppConfigStore,
              private pageConfigStore: PageConfigStore,
              private location: Location, ) {}

  ngOnInit(): void {
    this.menu = this.appConfig.state.menu;
    this.subs.push(
      this.appConfig.state$.subscribe(state => {
        this.menu = state.menu;
      }),
      this.pageConfigStore.state$.subscribe(state => {
        this.title = state.pageTitle;
      }),

    );
  }

  onClick() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
