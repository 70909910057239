import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-iframe-hotspot',
  templateUrl: './iframe-hotspot.component.html',
  styleUrls: ['./iframe-hotspot.component.less']
})
export class IframeHotspotComponent implements OnInit  {

  @Input() data: any;

  src: SafeUrl = '';

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.src);
  }

}
