export namespace IRoute {
  export const Routes = {
    MAIN_PAGE: {
      name: 'main-page',
      path: '',
      mask: ':lang'
    },
    BROADCAST_PAGE: {
      name: 'broadcast-page',
      path: 'broadcast',
      mask: 'broadcast/:id'
    },
    EXPO_PAGE: {
      name: 'exposition-page',
      path: 'expo',
      mask: 'expo'
    },
    NEWS_LIST_PAGE: {
      name: 'news-page',
      path: 'news',
      mask: 'news'
    },
    LOGIN_PAGE: {
      name: 'login-page',
      path: 'user/login',
      mask: 'login'
    },
    REGISTER_PAGE: {
      name: 'register-page',
      path: 'user/register',
      mask: 'register'
    }
  };

  export interface IOpenPageConfigs {
    page: string;
    entityId?: number;
    payload?: any;
  }
}
