import { Component, OnInit, Input } from '@angular/core';
import {AccessType, IMessageItem} from '../../interfaces/chat.interface';
import {AppConfigStore} from '../../../stores/app-config.store';
import {AppConfig} from '../../../core/config';
import {ChatStore} from '../../stores/chat.store';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.less']
})
export class MessageComponent implements OnInit {

  constructor(
    private appConfigStore: AppConfigStore,
    private chatStore: ChatStore) { }

  @Input() messageData: IMessageItem;
  @Input() prevMessage: IMessageItem;

  currentUserId: number | null;
  accessType = AccessType;
  userName: string | null;
  messageTime: number;

  ngOnInit(): void {
    this.currentUserId = this.appConfigStore.state.uid;

    this.setUserName();
    this.setMessageTime();
  }


  setUserName() {
    this.userName = this.messageData.authorName && this.messageData.authorSurName
      ? `${this.messageData.authorName} ${this.messageData.authorSurName}`
      : 'Anonymous'
  }

  setMessageTime() {
    this.messageTime = (this.messageData.changed ? this.messageData.changed : this.messageData.created ) * 1000;
  }

  setLike(liked) {
    this.chatStore.likeMessage(liked ? 'delete' : 'post', this.messageData.id)
  }
}
