import {Injectable} from '@angular/core';
import {ExpositionInterface} from "../interfaces/exposition.interface";
import {IStore, StoreService} from "../../core/store/store";


@Injectable()
export class ExpositionStoreService extends StoreService<any> implements IStore<any> {

  static defaultState: any = {
    scenes: [
      {
        id: 0,
        source: '/assets/img/expo/3/3.jpg',
        hotSpots: [
          {
            type: ExpositionInterface.hotspotTypes.NAVIGATION_ARROW,
            target: 1,
            position: {yaw: Math.PI, pitch: 0.4},
          },
          {
            type: ExpositionInterface.hotspotTypes.AUDIO,
            src: 'https://wavesurfer-js.org/example/elan/transcripts/001z.mp3',
            position: {yaw: -Math.PI / 4, pitch: 0},
          },
          {
            type: ExpositionInterface.hotspotTypes.CONTACT,
            position: {yaw: 0, pitch: 0},
          },
          {
            type: ExpositionInterface.hotspotTypes.MODEL_3D,
            modelType: ExpositionInterface.model3dTypes.model,
            position: {yaw: -Math.PI / 2, pitch: 0},
            viewport: {
              width: 768,
              height: 1080,
            },
            src: '/assets/models/ChloeIdle.FBX',
          },
          {
            type: ExpositionInterface.hotspotTypes.MODEL_3D,
            modelType: ExpositionInterface.model3dTypes.popup,
            position: {yaw: -Math.PI * 3 / 4, pitch: 0},
            viewport: {
              width: 768,
              height: 1080,
            },
            src: '/assets/models/ChloeIdle.FBX',
          },
          {
            type: ExpositionInterface.hotspotTypes.TEXT,
            text: 'Галлерея_1',
            position: {yaw: Math.PI / 4, pitch: -0.3},
          },
          {
            type: ExpositionInterface.hotspotTypes.IMAGES_GALLERY,
            galleryType: ExpositionInterface.galleryTypes.ONLY_IMAGE,
            images: [
              {
                src: '/assets/img/expo/gallery/nature-1.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-2.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-3.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-4.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-5.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-6.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-7.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-8.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-9.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-10.jpg',
                text: 'Some text',
              },
            ],
            position: {yaw: Math.PI / 4, pitch: 0},
          },
          {
            type: ExpositionInterface.hotspotTypes.TEXT,
            text: 'Галлерея_2',
            position: {yaw: Math.PI / 2, pitch: -0.3},
          },
          {
            type: ExpositionInterface.hotspotTypes.IMAGES_GALLERY,
            galleryType: ExpositionInterface.galleryTypes.WITH_THUMBS,
            images: [
              {
                src: '/assets/img/expo/gallery/nature-1.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-2.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-3.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-4.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-5.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-6.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-7.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-8.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-9.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-10.jpg',
                text: 'Some text',
              },
            ],
            position: {yaw: Math.PI / 2, pitch: 0},
          },
          {
            type: ExpositionInterface.hotspotTypes.TEXT,
            text: 'Галлерея_3',
            position: {yaw: Math.PI * 3 / 4, pitch: -0.3},
          },
          {
            type: ExpositionInterface.hotspotTypes.IMAGES_GALLERY,
            galleryType: ExpositionInterface.galleryTypes.WITH_TEXT,
            images: [
              {
                src: '/assets/img/expo/gallery/nature-1.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-2.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-3.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-4.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-5.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-6.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-7.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-8.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-9.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-10.jpg',
                text: 'Some text',
              },
            ],
            position: {yaw: Math.PI * 3 / 4, pitch: 0},
          },
          {
            type: ExpositionInterface.hotspotTypes.TEXT,
            text: 'Галлерея_4',
            position: {yaw: Math.PI, pitch: -0.3},
          },
          {
            type: ExpositionInterface.hotspotTypes.IMAGES_GALLERY,
            galleryType: ExpositionInterface.galleryTypes.CENTRED,
            images: [
              {
                src: '/assets/img/expo/gallery/nature-1.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-2.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-3.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-4.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-5.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-6.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-7.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-8.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-9.jpg',
                text: 'Some text',
              },
              {
                src: '/assets/img/expo/gallery/nature-10.jpg',
                text: 'Some text',
              },
            ],
            position: {yaw: Math.PI, pitch: 0},
          },

        ]
      },
      {
        id: 1,
        source: '/assets/img/expo/1/1.jpg',
        hotSpots: [
          {
            type: ExpositionInterface.hotspotTypes.NAVIGATION_ARROW,
            target: 2,
            position: {yaw: 3.14, pitch: 0.4},
          },
          {
            type: ExpositionInterface.hotspotTypes.IMAGE,
            src: 'https://40.img.avito.st/640x480/5832604640.jpg',
            position: {yaw: 4.71, pitch: 0},
          },
          {
            type: ExpositionInterface.hotspotTypes.FEEDBACK,
            position: {yaw: 2.36, pitch: 0},
          },

        ]
      },
      {
        id: 2,
        source: '/assets/img/expo/2/2.jpg',
        hotSpots: [
          {
            type: ExpositionInterface.hotspotTypes.NAVIGATION_ARROW,
            target: 0,
            position: {yaw: 3.14, pitch: 0.4},
          },
          {
            type: ExpositionInterface.hotspotTypes.IFRAME,
            src: 'https://www.youtube.com/embed/zqE4goWTvyw',
            position: {yaw: 4.71, pitch: 0},
          },
          {
            type: ExpositionInterface.hotspotTypes.IFRAME,
            src: 'https://player.vimeo.com/video/285023446?app_id=122963',
            position: {yaw: 1.57, pitch: 0},
          },
          {
            type: ExpositionInterface.hotspotTypes.IFRAME,
            src: 'http://africau.edu/images/default/sample.pdf',
            position: {yaw: 0.79, pitch: 0},
          },
          {
            type: ExpositionInterface.hotspotTypes.MAP,
            position: {yaw: 3.14, pitch: 0},
          },
        ]
      },

    ]
  };

  constructor() {
    super(ExpositionStoreService.defaultState);
  }

}
