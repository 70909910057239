import { Component, OnInit, OnDestroy } from '@angular/core';
import {ChatStore} from '../../stores/chat.store';
import {Subscription} from 'rxjs';
import {IChatChannelItem} from '../../interfaces/chat.interface';


@Component({
  selector: 'app-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.less']
})
export class ChatHeaderComponent implements OnInit, OnDestroy {

  constructor(private chatStore: ChatStore) { }
  subs: Subscription[] = [];
  chatTabs: IChatChannelItem[];
  currentChannel: number = null;

  ngOnInit(): void {
    this.subs.push(
      this.chatStore.state$.subscribe(state => {
        this.currentChannel = state.activeChannelId;

        if (state.chatChannels.length) {
          this.chatTabs = state.chatChannels;
        }
      }),
    )
  }

  selectChannel(channel) {
    this.chatStore.changeChannel(channel);
  }

  ngOnDestroy() {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
