import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {IConfirmDialog} from '../../interfaces/confirm-dialog.interface';
import {TranslateService} from '@ngx-translate/core';
import {AppConfig} from '../../core/config';

@Component({
  selector: 'cz-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.less'],
})
export class ConfirmDialogComponent {

  title: string;
  text: string;

  confirmText?: string;
  confirmAction: Function;

  cancelText?: string;
  cancelAction: Function;

  payload: any;

  isNeedCancelButton: boolean = false;

  constructor(
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialog,
  ) {
    this.title = data.title || 'CONFIRM_DIALOG.TITLE';
    this.text = data.text;
    this.payload = data.payload ? data.payload : {};

    this.confirmText = data.confirm && data.confirm.text || 'CONFIRM_DIALOG.CONFIRM_TEXT';
    this.confirmAction = data.confirm && data.confirm.action
      ? () => {
        data.confirm.action();
        this.closeDialog();
      }
      : this.closeDialog;

    if (data.cancel) {
      this.isNeedCancelButton = true;
      this.cancelText = data.cancel.text || 'CONFIRM_DIALOG.CANCEL_TEXT';
      this.cancelAction = data.cancel.action
        ? () => {
          data.cancel.action();
          this.closeDialog();
        }
        : this.closeDialog;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
