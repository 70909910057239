import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {IRoute} from './interfaces/router.interface';
import {BroadcastPageComponent} from './views/broadcast-page/broadcast-page.component';
import {NewsPageComponent} from './views/news-page/news-page.component';
import {ExpoPageComponent} from "./views/expo-page/expo-page.component";
import {AuthPageComponent} from './views/auth-page/auth-page.component';

const routes: Routes = [
  {
    path: '',
    component: BroadcastPageComponent,
    data: { pageName: IRoute.Routes.MAIN_PAGE.name },
  },
  {
    path: 'ru',
    component: BroadcastPageComponent,
    data: { pageName: IRoute.Routes.MAIN_PAGE.name },
  },
  {
    path: 'en',
    component: BroadcastPageComponent,
    data: { pageName: IRoute.Routes.MAIN_PAGE.name },
  },
  {
    path: IRoute.Routes.BROADCAST_PAGE.mask,
    component: BroadcastPageComponent,
    data: { pageName: IRoute.Routes.BROADCAST_PAGE.name },
  },
  {
    path: IRoute.Routes.EXPO_PAGE.mask,
    component: ExpoPageComponent,
    data: { pageName: IRoute.Routes.EXPO_PAGE.name },
  },
  {
    path: IRoute.Routes.NEWS_LIST_PAGE.path,
    component: NewsPageComponent,
    data: { pageName: IRoute.Routes.NEWS_LIST_PAGE.name },
  },
  {
    path: IRoute.Routes.LOGIN_PAGE.path,
    component: AuthPageComponent,
    data: { pageName: IRoute.Routes.LOGIN_PAGE.name },
  },
  {
    path: IRoute.Routes.REGISTER_PAGE.path,
    component: AuthPageComponent,
    data: { pageName: IRoute.Routes.REGISTER_PAGE.name },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule {}
