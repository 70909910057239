import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-navigation-arrow-hotspot',
  templateUrl: './navigation-arrow-hotspot.component.html',
  styleUrls: ['./navigation-arrow-hotspot.component.less']
})
export class NavigationArrowHotspotComponent {

  @Input() data: any;


  constructor() { }

}
