// tslint:disable-next-line:no-namespace
export namespace IAppConfig {
  export interface IConfig {
    apiUrl: string;
    apikey: string;
    default_language: LANGUAGES;
    blocks_display: IDisplayBlocks[];
    languages: LANGUAGES[];
    menu: IMenuLink[];
    themes: ITheme[];
    uid?: number;
    main_chat_channel: number,

    isLoggedIn: boolean;
    activeLanguage: LANGUAGES;
  }

  export interface IDisplayBlocks {
    show_advt: boolean;
    show_chats: boolean;
    show_help: boolean;
    show_right_sidebar: boolean;
    show_ticker: false
  }

  export interface IMenuLink {
    href: string;
    title: string;
    icon?: string;
  }

  export interface ITheme {
    background_header: string;
    background_left_sidebar: string;
    background_main: string;
    logo: string;
    slogan?: string;
    sponsors: ISponsorBlock[];
    banner: IBanner;
  }

  export enum LANGUAGES {
    RU = 'ru',
    EN = 'en',
  }

  export interface ISponsorBlock {
    author: number;
    commentsCount: number;
    id: number;
    image: number;
    liked: boolean;
    likes: number;
    link: string;
    linkTitle?: string;
    name: string;
    status: string;
    type: string;
    backgroundUrl: string;
    format: string;
    imageOriginalUrl: string;
    info: string;
    organizationType: string;
  }

  export interface IBanner {
    banner_link: string;
    image_url: string;
  }
}
