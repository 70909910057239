import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {timeFormat} from '../interfaces/forms.interface';

@Pipe({
  name: 'czPrintableTime'
})
export class PrintableTimePipe implements PipeTransform {
  transform(input: string|number|any): string {
    return input ? moment.utc(input).format(timeFormat) : '';
  }
}
