import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppConfigStore} from '../../stores/app-config.store';
import {IAppConfig} from '../../interfaces/app-config.interface';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-page-template',
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.less']
})
export class PageTemplateComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  displayBlocks: IAppConfig.IDisplayBlocks = null;

  @Input() content: any;

  constructor(private appConfig: AppConfigStore,) {}

  ngOnInit(): void {
    this.subs.push(this.appConfig.state$.subscribe(state => {
      this.displayBlocks = state.blocks_display[0];
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
