import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ExpositionComponent} from "./components/exposition/exposition.component";
import {ExpositionStoreService} from "./stores/exposition.store";
import {AngularYandexMapsModule, IConfig} from "angular8-yandex-maps";
import {MapHotspotComponent} from "./components/map-hotspot/map-hotspot.component";
import {AdDirective} from "../core/directives/ad.directive";
import {IframeHotspotComponent} from "./components/iframe-hotspot/iframe-hotspot.component";
import {Model3dHotspotComponent} from "./components/model-3d-hotspot/model-3d-hotspot.component";
import {AudioHotspotComponent} from "./components/audio-hotspot/audio-hotspot.component";
import {NavigationArrowHotspotComponent} from "./components/navigation-arrow-hotspot/navigation-arrow-hotspot.component";
import {TextHotspotComponent} from "./components/text-hotspot/text-hotspot.component";
import {ImageHotspotComponent} from "./components/image-hotspot/image-hotspot.component";
import {ImagesGalleryHotspotComponent} from "./components/images-gallery-hotspot/images-gallery-hotspot.component";
import {FeedbackHotspotComponent} from "./components/feedback-hotspot/feedback-hotspot.component";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ContactHotspotComponent} from "./components/contact-hotspot/contact-hotspot.component";
import {MatMaterialModule} from "../core/modules/mat-matrial.module";
import {Model3dDialogComponent} from "./components/model-3d-dialog/model-3d-dialog.component";
import {DialogService} from "../core/services/dialog.service";
import {Model3dViewportComponent} from "./components/model-3d-viewport/model-3d-viewport.component";

const mapConfig: IConfig = {
  apikey: 'API_KEY',
  lang: 'en_US',
};


@NgModule({
  imports: [
    CommonModule,
    MatMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    AngularYandexMapsModule.forRoot(mapConfig),
    NgxIntlTelInputModule,
  ],
  providers: [
    DialogService,
    ExpositionStoreService,
  ],
  declarations: [
    AdDirective,
    ExpositionComponent,
    NavigationArrowHotspotComponent,
    MapHotspotComponent,
    IframeHotspotComponent,
    Model3dHotspotComponent,
    Model3dDialogComponent,
    Model3dViewportComponent,
    AudioHotspotComponent,
    TextHotspotComponent,
    ImageHotspotComponent,
    ImagesGalleryHotspotComponent,
    FeedbackHotspotComponent,
    ContactHotspotComponent,
  ],
  exports: [
    ExpositionComponent,
    AngularYandexMapsModule,
  ]
})
export class ExpositionModule {}
