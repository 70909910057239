import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConfigStore} from '../../stores/app-config.store';
import {IAppConfig} from '../../interfaces/app-config.interface';
import {Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {IRoute} from '../../interfaces/router.interface';



@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.less']
})
export class ContentComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  theme: IAppConfig.ITheme = null;
  displayBlocks: IAppConfig.IDisplayBlocks = null;
  isLoading = true;
  hideRightSidebar = false;

  constructor(private appConfig: AppConfigStore, private router: Router) {}

  ngOnInit(): void {
    this.subs.push(this.appConfig.state$.subscribe(state => {
      this.theme = state.themes[0];
      this.displayBlocks = state.blocks_display[0];
      if (this.theme) {
        this.isLoading = false;
      }
    }),
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(ev => {
        this.hideRightSidebar = ev.url === `/${IRoute.Routes.REGISTER_PAGE.path}`
                              || ev.url === `/${IRoute.Routes.LOGIN_PAGE.path}`
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
