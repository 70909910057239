import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { RootComponent } from './root/root.component';
import {ContentComponent} from './components/content/content.component';
import {HeaderComponent} from './components/header/header.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CustomTranslationsLoader} from './translations/translations-loader.service';
import {CoreModule} from './core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {EventEmitterService} from './services/event-emitter.service';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {RedirectRouterService} from './services/redirect-router.service';
import {PageConfigStore} from './stores/page-config.store';
import {PrintableDatePipe} from './pipes/printable-date.pipe';
import {BroadcastPageComponent} from './views/broadcast-page/broadcast-page.component';
import {MenuComponent} from './components/menu/menu.component';
import {AppConfigStore} from './stores/app-config.store';
import { CookieService } from 'ngx-cookie-service';
import {FooterComponent} from './components/footer/footer.component';
import {PageTemplateComponent} from './components/page-template/page-template.component';
import {ExternalContentTemplateComponent} from "./components/external-content-template/external-content-template.component";
import {BroadcastCardComponent} from "./components/broadcast-card/broadcast-card.component";
import {PrintableTimePipe} from "./pipes/printable-time.pipe";
import {MainScreenSponsorsComponent} from './components/main-screen-sponsors/main-screen-sponsors.component';
import {MainScreenHeaderComponent} from './components/main-screen-header/main-screen-header.component';
import {BroadcastOverlayComponent} from "./components/broadcast-overlay/broadcast-overlay.component";
import {BroadcastResource} from "./resources/broadcast.resource";

import {ChatComponent} from './Chat/components/chat/chat.component';
import { ChatInputComponent } from './Chat/components/chat-input/chat-input.component';
import { ChatHeaderComponent } from './Chat/components/chat-header/chat-header.component';
import { MessageComponent } from './Chat/components/message/message.component';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MainScreenBannerComponent} from './components/main-screen-banner/main-screen-banner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {InternalContentTemplateComponent} from "./components/internal-content-template/internal-content-template.component";
import {ExpositionModule} from "./exposition/exposition.module";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {NgMarqueeModule} from 'ng-marquee';
import icons from '../assets/svg/svg-icons';
import { SvgIconsModule } from '@ngneat/svg-icon';
import {NewsPageComponent} from './views/news-page/news-page.component';
import {ExpoPageComponent} from "./views/expo-page/expo-page.component";
import {NgScrollbarModule} from "ngx-scrollbar";
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {BroadcastPageTemplateComponent} from "./components/broadcast-page-template/broadcast-page-template.component";
import {AuthPageComponent} from './views/auth-page/auth-page.component';
import {RightSidebarComponent} from './components/right-sidebar/right-sidebar.component';

const components = [
  RootComponent,
  ContentComponent,
  HeaderComponent,
  MenuComponent,
  ConfirmDialogComponent,
  BroadcastPageComponent,
  FooterComponent,
  PageTemplateComponent,
  BroadcastPageTemplateComponent,
  ExternalContentTemplateComponent,
  InternalContentTemplateComponent,
  BroadcastCardComponent,
  MainScreenSponsorsComponent,
  MainScreenHeaderComponent,
  BroadcastOverlayComponent,
  ChatComponent,
  ChatInputComponent,
  ChatHeaderComponent,
  MessageComponent,
  MainScreenBannerComponent,
  NewsPageComponent,
  ExpoPageComponent,
  RightSidebarComponent,
  AuthPageComponent,
];

const entryComponents = [
  ConfirmDialogComponent,
];

const resources = [
  BroadcastResource,
];

const services = [
  EventEmitterService,
  RedirectRouterService,
  CookieService,
];

const stores = [
  AppConfigStore,
  PageConfigStore,
];

const pipes = [
  PrintableDatePipe,
  PrintableTimePipe,
];

const modules = [
  BrowserModule,
  AppRoutingModule,
  CoreModule,
  BrowserAnimationsModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: CustomTranslationsLoader
    }
  }),
  FormsModule,
  ReactiveFormsModule,
  ToastrModule.forRoot(),
  TextFieldModule,
  ExpositionModule,
  InfiniteScrollModule,
  NgMarqueeModule,
  NgScrollbarModule,
  MatMenuModule,
  MatDialogModule,
  SvgIconsModule.forRoot({
    icons
  }),
];

@NgModule({
  declarations: [
    ...components,
    ...pipes,
  ],
  entryComponents: [
    ...entryComponents,
  ],
  imports: [
    ...modules,
  ],
  providers: [
    ...resources,
    ...services,
    ...stores,
    ...pipes,
  ],
  exports: [
    ...pipes,
  ],
  bootstrap: [RootComponent]
})
export class AppModule {
  constructor(private appConfig: AppConfigStore) {
    appConfig.initApp();
  }
}
