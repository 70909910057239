import {Component, ComponentFactory, ComponentFactoryResolver, OnInit, ViewChild} from '@angular/core';
import Marzipano from 'marzipano'
import {ExpositionStoreService} from "../../stores/exposition.store";
import {ExpositionInterface} from "../../interfaces/exposition.interface";
import {YandexMapComponent} from "angular8-yandex-maps/lib/components/yandex-map-component/yandex-map.component";
import {MapHotspotComponent} from "../map-hotspot/map-hotspot.component";
import {AdDirective} from "../../../core/directives/ad.directive";
import {IframeHotspotComponent} from "../iframe-hotspot/iframe-hotspot.component";
import {Model3dHotspotComponent} from "../model-3d-hotspot/model-3d-hotspot.component";
import {AudioHotspotComponent} from "../audio-hotspot/audio-hotspot.component";
import {NavigationArrowHotspotComponent} from "../navigation-arrow-hotspot/navigation-arrow-hotspot.component";
import {TextHotspotComponent} from "../text-hotspot/text-hotspot.component";
import {ImageHotspotComponent} from "../image-hotspot/image-hotspot.component";
import {ImagesGalleryHotspotComponent} from "../images-gallery-hotspot/images-gallery-hotspot.component";
import {FeedbackHotspotComponent} from "../feedback-hotspot/feedback-hotspot.component";
import {ContactHotspotComponent} from "../contact-hotspot/contact-hotspot.component";

@Component({
  selector: 'app-exposition',
  templateUrl: './exposition.component.html',
  styleUrls: ['./exposition.component.less']
})
export class ExpositionComponent implements OnInit{


  viewer: any;
  geometry: any;
  limiter: any;
  view: any;
  scene: any;
  scenes: any[] = [];

  elements: any[] = [];

  stopPropagationEventsList: string[] = [ 'touchstart', 'touchmove', 'touchend', 'touchcancel', 'wheel', 'mousewheel', 'mousedown', 'pointerdown' ];
  events: EventListener[] = []
  hotspotRadius = 1400;

  @ViewChild(AdDirective, {static: true}) hotspots: AdDirective;


  constructor(private expositionStore: ExpositionStoreService,
              private componentFactoryResolver: ComponentFactoryResolver, ) {
  }

  ngOnInit() {

    this.createView();

    this.expositionStore.state$.subscribe(state => {
      for (const item of state.scenes) {
        let source = Marzipano.ImageUrlSource.fromString(
          item.source
        );

        this.scene = this.viewer.createScene({
          source: source,
          geometry: this.geometry,
          view: this.view,
          pinFirstLevel: true
        });

        let hotspot;

        for (const point of item.hotSpots) {
          switch (point.type) {
            case ExpositionInterface.hotspotTypes.NAVIGATION_ARROW:
              hotspot = this.createHotspotComponent(NavigationArrowHotspotComponent, point);
              hotspot.addEventListener('click', () => {
                this.scenes.find( scene => scene.id === point.target).scene.switchTo()
              });
              break;

            case ExpositionInterface.hotspotTypes.IFRAME:
              hotspot = this.createHotspotComponent(IframeHotspotComponent, point);
              hotspot.style.width = '768px';
              hotspot.style.height = '432px';
              break;

            case ExpositionInterface.hotspotTypes.IMAGE:
              hotspot = this.createHotspotComponent(ImageHotspotComponent, point);
              hotspot.style.width = '1080px';
              hotspot.style.height = '608px';
              break;

            case ExpositionInterface.hotspotTypes.IMAGES_GALLERY:
              hotspot = this.createHotspotComponent(ImagesGalleryHotspotComponent, point);
              hotspot.style.width = '1080px';
              hotspot.style.height = '608px';
              break;

            case ExpositionInterface.hotspotTypes.AUDIO:
              hotspot = this.createHotspotComponent(AudioHotspotComponent, point);
              hotspot.style.width = '768px';
              hotspot.style.height = '148px';
              break;

            case ExpositionInterface.hotspotTypes.TEXT:
              hotspot = this.createHotspotComponent(TextHotspotComponent, point);
              break;

            case ExpositionInterface.hotspotTypes.MAP:
              hotspot = this.createHotspotComponent(MapHotspotComponent, point);
              hotspot.style.width = '768px';
              hotspot.style.height = '432px';
              break;

            case ExpositionInterface.hotspotTypes.MODEL_3D:
              hotspot = this.createHotspotComponent(Model3dHotspotComponent, point);
              if (point.modelType === ExpositionInterface.model3dTypes.popup) {
                hotspot.style.width = '608px';
                hotspot.style.height = '608px';
              }
              else {
                hotspot.style.width = '768px';
                hotspot.style.height = '1080px';
              }

              break;

            case ExpositionInterface.hotspotTypes.FEEDBACK:
              hotspot = this.createHotspotComponent(FeedbackHotspotComponent, point);
              hotspot.style.width = '600px';
              break;

            case ExpositionInterface.hotspotTypes.CONTACT:
              hotspot = this.createHotspotComponent(ContactHotspotComponent, point);
              hotspot.style.width = '1080px';
              break;
          }
        }

        this.scenes.push({
          id: item.id,
          scene: this.scene
        });
      }

      this.scenes[0].scene.switchTo();
    })
  }

  createView() {
    this.viewer = new Marzipano.Viewer(document.getElementById('pano'));
    this.geometry = new Marzipano.EquirectGeometry([{ width: 2048 }]);
    this.limiter = Marzipano.RectilinearView.limit.traditional(2048, 100*Math.PI/180);
    this.view = new Marzipano.RectilinearView({ pitch: 0, yaw: 0, fov: Math.PI }, this.limiter);
  }

  createHotspotComponent(componentClass, data?) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    const component = this.hotspots.viewContainerRef.createComponent(componentFactory);
    if (data) {
      (<any>component.instance).data = data;
    }
    const componentElement = component.location.nativeElement;
    this.stopTouchAndScrollEventPropagation(componentElement);
    this.scene.hotspotContainer().createHotspot(componentElement, data.position, { perspective: { radius: this.hotspotRadius, extraTransforms: data.extraTransforms || '' }});
    return componentElement;
  }

  stopTouchAndScrollEventPropagation(element) {
    this.stopPropagationEventsList.forEach( (eventName) => {
      this.events.push(
        element.addEventListener(eventName, (event) => {
          event.stopPropagation();
        })
      )
    })
  }


}
