import {Component, OnDestroy, OnInit} from '@angular/core';
import {BroadcastResource} from "../../resources/broadcast.resource";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {PageConfigStore} from "../../stores/page-config.store";
import {AppConfig} from "../../core/config";

@Component({
  selector: 'app-main-page',
  templateUrl: './broadcast-page.component.html',
  styleUrls: ['./broadcast-page.component.less']
})

export class BroadcastPageComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];

  constructor( private broadcastResource: BroadcastResource,
               private router: Router,
               private route: ActivatedRoute,
               private pageConfigStore: PageConfigStore,
  ) {
  }

  content: any;

  ngOnInit() {
    this.route.params.subscribe(() => this.init())
  }

  init() {
    this.pageConfigStore.updateTitle('');
    const broadcastId = this.route.snapshot.params['id'] || '4147';

    this.subs.push(this.broadcastResource.getClosestBroadcast(broadcastId).subscribe( result => {
      this.pageConfigStore.updateTitle(result?.data?.name);
      const queries = [];
      for (const event of result.data.relationships.find(relationship => relationship.type === 'event').identifiers) {
        queries.push(this.broadcastResource.getEvent(event))
      }

      if (result.data.relatedEvents.find((item) => item.dateStart * 1000 > Date.now())) {
        result.data.relatedEvents.find((item) => item.dateStart * 1000 > Date.now())['next'] = true;
      }

      for (const item of result.data.relatedEvents.filter((item) => item.dateStart * 1000 < Date.now() && (item.dateEnd || result.data.dateEnd) * 1000 > Date.now())) {
        item['current'] = true;
      }

      this.content = {
        iframeUrl: `${AppConfig.apiUrl}/ru/node/${result.data.id}?mode=minimal`,
        data: result.data,
        closestBroadcasts: result.data.closest_broadcasts,
        schedule: result.data.relatedEvents,
      }
    }))
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
