import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {AppConfigStore} from '../../stores/app-config.store';
import {IAppConfig} from '../../interfaces/app-config.interface';
import {Subscription} from 'rxjs';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {IRoute} from "../../interfaces/router.interface";
import {ActivatedRoute, ActivationStart, Router} from "@angular/router";

@Component({
  selector: 'app-external-content-template',
  templateUrl: './external-content-template.component.html',
  styleUrls: ['./external-content-template.component.less']
})
export class ExternalContentTemplateComponent implements OnInit, OnChanges, OnDestroy {
  subs: Subscription[] = [];

  @Input() content: any;

  src: SafeUrl = '';

  constructor(private appConfig: AppConfigStore,
              private route: ActivatedRoute,
              private router: Router,
              private sanitizer: DomSanitizer, ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes) {
    if (changes['content']) {
      this.src = '';
      setTimeout( () => {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.iframeUrl);
      })
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
