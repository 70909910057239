import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectRouterService {
  constructor(private router: Router) { }

  openPage() {

  }
}
