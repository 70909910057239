
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {IRoute} from './app/interfaces/router.interface';

if (environment.production) {
  enableProdMode();
}

// Do not bootstrap angular on these pages, because they just static and came from Drupal.
const angularRoutes = [
  IRoute.Routes.MAIN_PAGE.path,
];

const skipAngular = false;
// Compare the requested path with angular routes to decide, do we need to bootstrap angular or not.
// angularRoutes.forEach((route) => {
//   // Compare by pattern.
//   if (route[route.length - 1] === '*') {
//     const routeMaskLength = route.length - 2;
//     if (window.location.pathname.substr(1, routeMaskLength) === route.substr(0, routeMaskLength)) {
//       skipAngular = false;
//     }
//   }
//   // Exact comparison.
//   if (window.location.pathname.substr(1, window.location.pathname.length - 1).includes(route)) {
//     skipAngular = false;
//   } else {
//     const lang = window.location.pathname.substr(0, 4);
//     if (['/ru/', '/ru', '/en/', '/en'].includes(lang)) {
//       if (window.location.pathname.substr(4, window.location.pathname.length - 1).includes(route)) {
//         skipAngular = false;
//       }
//     }
//   }
// });

if (!skipAngular) {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}
