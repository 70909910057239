import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {PageConfigStore} from "../../stores/page-config.store";
import {ActivatedRoute} from '@angular/router';
import {IRoute} from '../../interfaces/router.interface';


@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.less']
})

export class AuthPageComponent implements OnInit {
  constructor(
    private pageConfigStore: PageConfigStore,
    private activatedRoute: ActivatedRoute
  ) {
  }

  content: any;

  ngOnInit() {
    const pageData: any = {};
    if (this.activatedRoute.snapshot.url[1].path === IRoute.Routes.LOGIN_PAGE.mask) {
      pageData['iframeUrl']  = 'https://rosmedobr2020.gate1.campuz.org/ru/user/login?mode=minimal';
    }

    if (this.activatedRoute.snapshot.url[1].path === IRoute.Routes.REGISTER_PAGE.mask) {
      pageData['iframeUrl'] = 'https://rosmedobr2020.gate1.campuz.org/ru/user/register?mode=minimal';
    }

    this.setPageData(pageData);
  }

  setPageData(pageData) {
    this.pageConfigStore.updateTitle(pageData.title);
    this.content = {
      iframeUrl: pageData.iframeUrl,
    };
  }
}
