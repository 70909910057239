import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppConfigStore} from '../../stores/app-config.store';
import {IAppConfig} from '../../interfaces/app-config.interface';
import {Subscription} from 'rxjs';
import {ActivatedRoute, ActivationStart, Router} from "@angular/router";
import {IRoute} from "../../interfaces/router.interface";

@Component({
  selector: 'app-broadcast-page-template',
  templateUrl: './broadcast-page-template.component.html',
  styleUrls: ['./broadcast-page-template.component.less']
})
export class BroadcastPageTemplateComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  displayBlocks: IAppConfig.IDisplayBlocks = null;
  page: string;

  isOverlayShowed: boolean = false;
  isShowFiretoWidget: boolean = false;

  @Input() content: any;

  constructor(private appConfig: AppConfigStore,
              private route: ActivatedRoute,
              private router: Router, ) {}

  ngOnInit(): void {
    this.page = this.route.snapshot.data.pageName;
    this.subs.push(
      this.router.events.subscribe( (event) => {
        if(event instanceof ActivationStart){
          this.page = event.snapshot.data.pageName;
        }
      })
    );

    this.subs.push(this.appConfig.state$.subscribe(state => {
      this.displayBlocks = state.blocks_display[0];
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(subscription => subscription.unsubscribe());
  }
}
