import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-model-3d-dialog',
  templateUrl: './model-3d-dialog.component.html',
  styleUrls: ['./model-3d-dialog.component.less']
})
export class Model3dDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

}
